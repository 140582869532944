import { Checkbox, Popover, Spin } from "antd";
import ConfigurableTable from "components/shared/table/ConfigurableTable";
import { ALL, PAYER_MARKET_SHARE_COLUMNS, TABLE_NAME_PAYER_MARKET_SHARE } from "constants/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from "react-redux";
import classes from '../../customer/payer-mix/PayerMix.module.scss';
import { dropdownOptions, formatOptionLabel, getRestrictedColumn, ValueContainer } from "utils/utils";
import GNEAsyncPagination from "components/shared/dropdown-with-pagination/GNEAsyncPagination";
import { exportTableData, loadDropDown, loadTableData } from "store/payer/payer-view-actions";
import { wrapMenuList } from "react-select-async-paginate";


const PayerMarketShare = () => {
  const user = useSelector(state => state.Auth.userClaimObject);
  const [offset, setOffset] = useState({
    AccountPayerEcosystem: 0,
    AccountPayerProduct: 0,
    AccountPayerSquad: 0,
    AccountPayerPayer: 0
  });
  const [searchOffset, setSearchOffset] = useState({
    AccountPayerEcosystem: 0,
    AccountPayerProduct: 0,
    AccountPayerSquad: 0,
    AccountPayerPayer: 0
  });
  const firstLoading = useRef({
    AccountPayerEcosystem: true,
    AccountPayerProduct: true,
    AccountPayerSquad: true,
    AccountPayerPayer: true
  });
  const shouldLoadMore = useRef({
    AccountPayerEcosystem: false,
    AccountPayerProduct: false,
    AccountPayerSquad: false,
    AccountPayerPayer: false
  });

  const [selectedValues, setSelectedValues] = useState({
    AccountPayerEcosystem: [],
    AccountPayerProduct: [],
    AccountPayerSquad: [],
    AccountPayerPayer: []
  })

  const [searchedTexts, setSearchedText] = useState({
    AccountPayerEcosystem: '',
    AccountPayerProduct: '',
    AccountPayerSquad: '',
    AccountPayerPayer: ''
  })

  const [tableSortData, setTableSortData] = useState({
    sortByOrder: 'DESC',
    sortByColumn: 'Benefit_Type'
  })

  const [popoverVisible, setPopoverVisible] = useState(false);
  const isLoading = useSelector(
    (state) => state?.PayerView?.accountPayerLoader
  );
  const isExporting = useSelector(
    (state) => state?.PayerView?.exportPayerMarketShare
  );
  const columnRestriction = ['Market_Share'];
  const [pageIndex, setPageIndex] = useState(1);

  const [selectedColumns, setSelectedColumns] = useState(
    PAYER_MARKET_SHARE_COLUMNS.map((column) => {
      return column.isMandatory === 'Y' && column.dataIndex;
    })
  );
  const handleColumnSelection = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };
  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const columnSelectionPanel = (
    <Checkbox.Group
      defaultValue={selectedColumns}
      onChange={handleColumnSelection}
      className={classes.checkboxGroup}
      key={Math.random()}>
      {PAYER_MARKET_SHARE_COLUMNS.map((column) => (
        <div
          className={classes.checkboxWrapper}
          key={column.id}>
          <Checkbox
            className={classes.checkbox}
            key={column.key}
            value={column.key}
            disabled={columnRestriction.find(x => x === column.key)}
          // onChange={handleCheckboxChange}
          >
            {column.title.length > 15
              ? `${column.title.substring(0, 20)}...`
              : column.title}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  );

  const exportTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>Send to Excel</Tooltip>
  );
  const size = { x: 1100, y: 400 };
  let payerFilterExpression = 'Account_ID={"100000000379009"}'

  const popoverContent = (
    <div className={classes.acc_custom_popup}>{columnSelectionPanel}</div>
  );
  const filteredColumns = PAYER_MARKET_SHARE_COLUMNS.filter((column) =>
    selectedColumns.includes(column.dataIndex)
  );

  const pagination = () => ({
    total: tableData.totalSize,
    onChange: (page, size) => {
      setPageIndex(page);
    },
    pageSizeOptions: [20],
    showSizeChanger: false,
    pageSize: 20,
    current: pageIndex
  });

  const buildExpression = fieldName => {
    const payerExp = selectedValues.AccountPayerPayer.length > 0
      ? 'Payer_Name={"' + selectedValues.AccountPayerPayer.map((a) => a.label).join('","') + '"}'
      : undefined;
    const squadExp = selectedValues.AccountPayerSquad.length > 0
      ? 'Squad={"' +
      selectedValues.AccountPayerSquad.map((a) => a.label).join('","') +
      '"}'
      : undefined;
    const ecosystemExp = selectedValues.AccountPayerEcosystem.length > 0
      ? 'Ecosystem={"' + selectedValues.AccountPayerEcosystem.map((a) => a.label).join('","') + '"}'
      : undefined;
    const productExp = selectedValues.AccountPayerProduct.length > 0
      ? 'Product={"' + selectedValues.AccountPayerProduct.map((a) => a.label).join('","') + '"}'
      : undefined;

    if (payerExp !== undefined && fieldName !== 'AccountPayerPayer') {
      payerFilterExpression = payerFilterExpression?.concat(`, ${payerExp}`);
    }
    if (ecosystemExp !== undefined && fieldName !== 'AccountPayerEcosystem') {
      payerFilterExpression = payerFilterExpression?.concat(
        `, ${ecosystemExp}`
      );
    }
    if (productExp !== undefined && fieldName !== 'AccountPayerProduct') {
      payerFilterExpression = payerFilterExpression?.concat(`, ${productExp}`);
    }
    if (squadExp !== undefined && fieldName !== 'AccountPayerSquad') {
      payerFilterExpression = payerFilterExpression?.concat(
        `, ${squadExp}`
      );
    }
    return payerFilterExpression;
  };

  const handleSorting = (column, order) => {
    if (column && order) {
      setTableSortData({
        sortByColumn: column,
        sortByDirection: order === 'descend' ? "DESC" : "ASC"
      })
    }
  };

  const [tableData, setTableData] = useState({
    data: [],
    hasMoreElement: false,
    offset: 0,
    totalSize: 0
  });

  useEffect(() => {
    getTableData();
  }, [selectedValues, pageIndex, tableSortData, selectedColumns]);

  useEffect(() => {
    setSearchOffset({
      AccountPayerEcosystem: 0,
      AccountPayerProduct: 0,
      AccountPayerSquad: 0,
      AccountPayerPayer: 0
    });
    setOffset({
      AccountPayerEcosystem: 0,
      AccountPayerProduct: 0,
      AccountPayerSquad: 0,
      AccountPayerPayer: 0
    });
    setPageIndex(1)
  }, [selectedValues]);

  const getTableData = async () => {
    const params = {
      filterCriteria: [
        {
          CustomerId: "100000000379009",
          EcosystemName: selectedValues.AccountPayerEcosystem.map(x => x.value),
          Product: selectedValues.AccountPayerProduct.map(x => x.value),
          Squad: selectedValues.AccountPayerSquad?.length ? selectedValues.AccountPayerSquad[0].value : "",
          SortByColumn: tableSortData.sortByColumn,
          SortByDirection: tableSortData.sortByDirection || "DESC",
          Offset: (pageIndex - 1) * 20,
          Dimension: selectedColumns.filter(x => !columnRestriction.includes(x)),
          BookOfBusiness: [],
          ParentPayer: [],
          Payer: selectedValues.AccountPayerPayer.map(x => (
            {
              EntityName: x.value,
              EntityType: "Payer",
              MdmId: ""
            })),
          BenefitType: "Medical",
        }
      ],
      userContext: {
        roleCode: user.roleId,
        unixId: user.unixId
      }
    }
    const { result: { value: { value: { HasMoreElement, Values, TotalSize, Offset } } } } = await loadTableData(params);
    setTableData({
      ...tableData,
      data: Values,
      HasMoreElement: HasMoreElement,
      offset: Offset,
      totalSize: TotalSize
    })
  }

  const downloadData = async () => {
    const params = {
      filterCriteria: [
        {
          CustomerId: "100000000379009",
          EcosystemName: selectedValues.AccountPayerEcosystem.map(x => x.value),
          Product: selectedValues.AccountPayerProduct.map(x => x.value),
          Squad: selectedValues.AccountPayerSquad?.length ? selectedValues.AccountPayerSquad[0].value : "",
          SortByColumn: tableSortData.sortByColumn,
          SortByDirection: tableSortData.sortByDirection || "DESC",
          Offset: 0,
          Dimension: selectedColumns.filter(x => !columnRestriction.includes(x)),
          BookOfBusiness: [],
          ParentPayer: [],
          Payer: selectedValues.AccountPayerPayer.map(x => (
            {
              EntityName: x.value,
              EntityType: "Payer",
              MdmId: ""
            })),
          BenefitType: "Medical",
        }
      ],
      userContext: {
        roleCode: user.roleId,
        unixId: user.unixId
      }
    }
    const exportResponse = await exportTableData(params);
    const FileDownload = require('js-file-download');
    FileDownload(exportResponse, 'PayerMarketShare.xlsx')

  };


  const callLoadOptions = async (inputValue, firstLoad, fieldName) => {
    const params = {
      userContext: {
        unixId: user.unixId,
        roleCode: user.roleId
      },
      criteria: {
        accountId: '',
        fieldName,
        offset: inputValue !== '' ? searchedTexts[fieldName] === inputValue ? searchOffset[fieldName] : 0 : offset[fieldName],
        searchText: inputValue,
        expression: buildExpression(fieldName)
      }
    };
    const { result: { value: { value: { HasMoreElement, CurrentListSize, Values } } } } = await loadDropDown(params);

    // if (HasMoreElement) {
    if (inputValue === '') {
      setOffset((prevValue) => ({ ...prevValue, [fieldName]: offset[fieldName] + CurrentListSize }));
      setSearchOffset((prevValue) => ({ ...prevValue, [fieldName]: 0 }));
    }
    else if (searchedTexts[fieldName] === inputValue) {
      setSearchOffset((prevValue) => ({ ...prevValue, [fieldName]: prevValue[fieldName] + CurrentListSize }));
      setOffset((prevValue) => ({ ...prevValue, [fieldName]: 0 }));
    }
    else {
      setSearchOffset((prevValue) => ({ ...prevValue, [fieldName]: CurrentListSize }));
      setOffset((prevValue) => ({ ...prevValue, [fieldName]: 0 }));
    }
    // }
    setSearchedText({ ...searchedTexts, [fieldName]: inputValue })
    const response = {
      options: Values?.flatMap(item => ({ value: item, label: item })),
      hasMore: HasMoreElement,
    };
    return response
  };

  const handleOnChange = (value, field) => {
    setSelectedValues({ ...selectedValues, [field]: value });
  }

  const shouldLoadMoreOptions = (scrollHeight, clientHeight, scrollTop, fieldName) => {
    if (firstLoading.current[fieldName]) {
      firstLoading.current[fieldName] = false;
      return true;
    }
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    const loadMore = bottomBorder < scrollTop;
    shouldLoadMore.current.fieldName = loadMore;
    return loadMore;
  };

  return (
    <div className='full-width-in-container light-grey'>
      <div
        id='PayerMarketShare'
        className='pt-3'>
        <Row>
          <Col
            className='pt-3 d-flex align-item-center'
            md={6}>
            <p className='title title__bold title__medium mb-1'>Payer Market Share</p>
          </Col>
          <Col
            className='pt-3 d-flex justify-content-end'
            md={6}>
            <Popover
              content={popoverContent}
              trigger='click'
              open={popoverVisible}
              onOpenChange={handlePopoverVisibleChange}>
              <i
                className={`icon icon__affiliations_standardTable_settings mt-3 me-3`}
              />
            </Popover>
            <OverlayTrigger
              placement='top'
              overlay={exportTooltip}>
              <i
                onClick={downloadData}
                className={`icon icon__affiliations_export_excel1 mt-3`}
              />
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='title__x-small'>Ecosystem</label>
            <GNEAsyncPagination
              value={selectedValues.AccountPayerEcosystem || ''}
              loadOptions={(inputValue, firstLoad) => callLoadOptions(inputValue, firstLoad, 'AccountPayerEcosystem')}
              onChange={(e) => handleOnChange(e, 'AccountPayerEcosystem')}
              isMulti={true}
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              placeholder={ALL}
              closeMenuOnSelect={false}
              isClearable
              shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => shouldLoadMoreOptions(scrollHeight, clientHeight, scrollTop, 'AccountPayerEcosystem')}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
              cacheUniqs={[
                selectedValues.AccountPayerPayer,
                selectedValues.AccountPayerProduct,
                selectedValues.AccountPayerSquad
              ]}
            />
          </Col>
          <Col>
            <label className='title__x-small'>Product</label>
            <GNEAsyncPagination
              value={selectedValues.AccountPayerProduct || ''}
              loadOptions={(inputValue, firstLoad) => callLoadOptions(inputValue, firstLoad, 'AccountPayerProduct')}
              onChange={(e) => handleOnChange(e, 'AccountPayerProduct')}
              isMulti={true}
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              placeholder={ALL}
              closeMenuOnSelect={false}
              isClearable
              shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => shouldLoadMoreOptions(scrollHeight, clientHeight, scrollTop, 'AccountPayerProduct')}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
              cacheUniqs={[
                selectedValues.AccountPayerEcosystem,
                selectedValues.AccountPayerPayer,
                selectedValues.AccountPayerSquad
              ]}
            />
          </Col>
          <Col>
            <label className='title__x-small'>Squad</label>
            <GNEAsyncPagination
              value={selectedValues.AccountPayerSquad || ''}
              loadOptions={(inputValue, firstLoad) => callLoadOptions(inputValue, firstLoad, 'AccountPayerSquad')}
              onChange={(e) => handleOnChange(e, 'AccountPayerSquad')}
              isMulti={true}
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              placeholder={ALL}
              closeMenuOnSelect={false}
              isClearable
              shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => shouldLoadMoreOptions(scrollHeight, clientHeight, scrollTop, 'AccountPayerSquad')}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
              cacheUniqs={[
                selectedValues.AccountPayerEcosystem,
                selectedValues.AccountPayerPayer,
                selectedValues.AccountPayerProduct,
              ]}
            />
          </Col>
          <Col>
            <label className='title__x-small'>Payer</label>
            <GNEAsyncPagination
              value={selectedValues.AccountPayerPayer || ''}
              loadOptions={(inputValue, firstLoad) => callLoadOptions(inputValue, firstLoad, 'AccountPayerPayer')}
              onChange={(e) => handleOnChange(e, 'AccountPayerPayer')}
              isMulti={true}
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              placeholder={ALL}
              closeMenuOnSelect={false}
              isClearable
              shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => shouldLoadMoreOptions(scrollHeight, clientHeight, scrollTop, 'AccountPayerPayer')}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
              cacheUniqs={[
                selectedValues.AccountPayerEcosystem,
                selectedValues.AccountPayerSquad,
                selectedValues.AccountPayerProduct
              ]}
            />
          </Col>
        </Row>
        &nbsp;
      </div>

      <Row
        id='PayerMarketShare'
        className={classes.sitePerformanceContainer}>
        <Col xs={12}>
          <Spin spinning={isLoading || isExporting}>
            <ConfigurableTable
              rows={tableData.data}
              columns={getRestrictedColumn(
                TABLE_NAME_PAYER_MARKET_SHARE,
                filteredColumns,
                columnRestriction
              )}
              enableColumnSelection={false}
              enableExport={false}
              exportFileName='PayerMarketShare'
              tableName={TABLE_NAME_PAYER_MARKET_SHARE}
              expression={payerFilterExpression}
              isGlobalExport={true}
              pagination={pagination()}
              isGlobalSort={true}
              onSort={handleSorting}
              dimentions={size}
            />
          </Spin>
        </Col>
      </Row>
    </div>
  );
}

export default PayerMarketShare;