export const payerViewInitialState = {
  payerMarketShareLoader: false,
  exportPayerMarketShare: false,
  payerPerformanceLoader: false,
  exportPayerPerformance: false,
  columnsRestiction:{},
  PayerMarketShareFilters: {
    Ecosystem: {
      options: [],
      selectedValue: [],
    },
    Product: {
      options: [],
      selectedValue: [],
    },
    Squad: {
      options: [],
      selectedValue: [],
    },
    Payer: {
      options: [],
      selectedValue: [],
    }
  },
  PayerPerformaceFilters: {
    Ecosystem: {
      options: [],
      selectedValue: [],
    },
    Squad: {
      options: [],
      selectedValue: [],
    },
    Product: {
      options: [],
      selectedValue: [],
    },
    Site: {
      options: [],
      selectedValue: [],
    },
    Plan: {
      options: [],
      selectedValue: [],
    }
  },
  QuickAnalyticsFilters: {
    Ecosystem: {
      options: [],
      selectedValue: [],
    },
    Product: {
      options: [],
      selectedValue: [],
    },
    Squad: {
      options: [],
      selectedValue: [],
    },
    Payer: {
      options: [],
      selectedValue: [],
    },
    TimePeriod: {
      options: [],
      selectedValue: [],
    }
  }
};
