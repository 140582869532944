import { createSlice } from '@reduxjs/toolkit';
import { payerViewInitialState } from './payerViewInitialState';

const payerViewSlice = createSlice({
  name: 'payerView',
  initialState: payerViewInitialState,
  reducers: {
    setPayerMarketShareLoader: (state, action) => {
      state.payerMarketShareLoader = action.payload;
    },
    setPayerPerformanceLoader: (state, action) => {
      state.payerMarketShareLoader = action.payload;
    },
    setPayerMarketShareEcosystemFilters: (state, action) => {
      state.PayerMarketShareFilters.Ecosystem = action.payload;
    },
    setPayerMarketShareProductFilters: (state, action) => {
      state.PayerMarketShareFilters.Product = action.payload;
    },
    setPayerMarketShareSquadFilters: (state, action) => {
      state.PayerMarketShareFilters.Squad = action.payload;
    },
    setPayerMarketSharePayerFilters: (state, action) => {
      state.PayerMarketShareFilters.Payer = action.payload;
    },
    setPayerPerformanceEcosystemFilters: (state, action) => {
      state.PayerPerformanceFilters.Ecosystem = action.payload;
    },
    setPayerPerformanceProductFilters: (state, action) => {
      state.PayerPerformanceFilters.Product = action.payload;
    },
    setPayerPerformanceSquadFilters: (state, action) => {
      state.PayerPerformanceFilters.Squad = action.payload;
    },
    setPayerPerformanceSiteFilters: (state, action) => {
      state.PayerPerformanceFilters.Site = action.payload;
    },
    setPayerPerformancePlanFilters: (state, action) => {
      state.PayerPerformanceFilters.Plan = action.payload;
    },
    setQuickAnalyticsEcosystemFilters: (state, action) => {
      state.QuickAnalyticsFilters.Ecosystem = action.payload;
    },
    setQuickAnalyticsProductFilters: (state, action) => {
      state.QuickAnalyticsFilters.Product = action.payload;
    },
    setQuickAnalyticsSquadFilters: (state, action) => {
      state.QuickAnalyticsFilters.Squad = action.payload;
    },
    setQuickAnalyticsPayerFilters: (state, action) => {
      state.QuickAnalyticsFilters.Payer = action.payload;
    },
    setQuickAnalyticsTimePeriodFilters: (state, action) => {
      state.QuickAnalyticsFilters.TimePeriod = action.payload;
    }
  },
});

export const {
  setPayerMarketShareLoader,
  setPayerMarketShareEcosystemFilters,
  setPayerMarketSharePayerFilters,
  setPayerMarketShareProductFilters,
  setPayerMarketShareSquadFilters,
  setPayerPerformanceSiteFilters,
  setPayerPerformanceEcosystemFilters,
  setPayerPerformanceSquadFilters,
  setPayerPerformancePlanFilters,
  setPayerPerformanceProductFilters,
  setQuickAnalyticsEcosystemFilters,
  setQuickAnalyticsPayerFilters,
  setQuickAnalyticsProductFilters,
  setQuickAnalyticsSquadFilters,
  setQuickAnalyticsTimePeriodFilters
} = payerViewSlice.actions;

export const payerViewSliceActions = payerViewSlice.actions;

export default payerViewSlice.reducer;
