/* eslint-disable react-hooks/exhaustive-deps */
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Col, Container, Row } from 'react-bootstrap';

import Layout from 'components/layout/Layout';
import PayerMarketShare from '../payer-market-share/PayerMarketShare';
import PayerPerformance from '../payer-performance/PayerPerformance';
import QuickAnalytics from '../quick-analytics/QuickAnalytics';

const PayerView = () => {
  return (
    <ProSidebarProvider>
      <Layout showRighSideBar={false}>
        <Container className='gne-container customer-container'>
          <div className='full-width-in-container light-grey -10'>
            <div
              id='PayerMarketShare'
              className='pt-3'>
              <Row>
                <Col
                  className='pt-3 d-flex align-item-center'
                  md={6}
                  style={{ alignItems: 'baseline' }}>
                  <i className='icon icon__customer-view--title'></i>
                  <p className='title title__bold title__medium mb-1'>Payer View</p>
                </Col>
              </Row>
            </div>
          </div>
          <PayerMarketShare />
          <PayerPerformance />
          <QuickAnalytics />
        </Container>
      </Layout>
    </ProSidebarProvider>
  );
};

export default PayerView;
