import { ALL } from "constants/constants";
import { useEffect, useRef, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import { useSelector } from "react-redux";
import classes from '../../customer/payer-mix/PayerMix.module.scss';
import { dropdownOptions, formatOptionLabel, ValueContainer } from "utils/utils";
import ReactEcharts from 'echarts-for-react';
import { wrapMenuList } from "react-select-async-paginate";
import GNEAsyncPagination from "components/shared/dropdown-with-pagination/GNEAsyncPagination";
import { loadChartData, loadDropDown } from "store/payer/payer-view-actions";

const QuickAnalytics = () => {
  const user = useSelector(state => state.Auth.userClaimObject);
  let payerFilterExpression = 'Account_ID={"100000000379009"}'


  const defaultMonthData = {
    title: {
      text: 'Market Share By Month',
      textStyle: { fontSize: 14 }
    },
    grid: { top: 40, right: 10, bottom: 24, left: 45 },
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [],
        type: 'line',
        smooth: false,
      },
    ],
    tooltip: {
      trigger: 'axis',
    }
  }
  const [marketShareByMonthData, setMarketShareByMonthData] = useState(defaultMonthData);

  const defaultEcosystemData = {
    title: {
      text: 'Market Share By Ecosystem',
      textStyle: { fontSize: 14 }
    },
    grid: { top: 40, right: 8, bottom: 24, left: 43 },
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: [],
        type: 'bar',
        smooth: false,
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  }

  const [marketShareByEcosystemData, setMarketShareByEcosystemData] = useState(defaultEcosystemData);

  const defaultMonthPayerData = {
    title: {
      text: 'Market Share By Month & Payer',
      textStyle: { fontSize: 14 }
    },
    grid: { top: 40, right: 8, bottom: 24, left: 43 },
    // legend: {},
    tooltip: {
      trigger: 'axis'
    },
    yAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    xAxis: {
      type: 'value'
    },
    series: []
  }
  const [marketShareByMonthAndPayerData, setMarketShareByMonthAndPayerData] = useState(defaultMonthPayerData);

  const [offset, setOffset] = useState({
    AccountPayerEcosystem: 0,
    AccountPayerProduct: 0,
    AccountPayerSquad: 0,
    AccountPayerPayer: 0,
    AccountPayerTimePeriod: 0
  });
  const [searchOffset, setSearchOffset] = useState({
    AccountPayerEcosystem: 0,
    AccountPayerProduct: 0,
    AccountPayerSquad: 0,
    AccountPayerPayer: 0,
    AccountPayerTimePeriod: 0
  });
  const firstLoading = useRef({
    AccountPayerEcosystem: true,
    AccountPayerProduct: true,
    AccountPayerSquad: true,
    AccountPayerPayer: true,
    AccountPayerTimePeriod: true
  });
  const shouldLoadMore = useRef({
    AccountPayerEcosystem: false,
    AccountPayerProduct: false,
    AccountPayerSquad: false,
    AccountPayerPayer: false,
    AccountPayerTimePeriod: false
  });

  const [selectedValues, setSelectedValues] = useState({
    AccountPayerEcosystem: [],
    AccountPayerProduct: null,
    AccountPayerSquad: null,
    AccountPayerPayer: [],
    AccountPayerTimePeriod: { value: 'R12M', label: 'R12M' }
  })

  const [searchedTexts, setSearchedText] = useState({
    AccountPayerEcosystem: '',
    AccountPayerProduct: '',
    AccountPayerSquad: '',
    AccountPayerPayer: ''
  })

  useEffect(() => {
    setSearchOffset({
      AccountPayerEcosystem: 0,
      AccountPayerProduct: 0,
      AccountPayerSquad: 0,
      AccountPayerPayer: 0,
      AccountPayerTimePeriod: 0
    });
    setOffset({
      AccountPayerEcosystem: 0,
      AccountPayerProduct: 0,
      AccountPayerSquad: 0,
      AccountPayerPayer: 0,
      AccountPayerTimePeriod: 0
    });
    updateCharts()
  }, [selectedValues]);

  const buildExpression = fieldName => {
    const payerExp = selectedValues.AccountPayerPayer.length > 0
      ? 'Payer_Name={"' + selectedValues.AccountPayerPayer.map((a) => a.label).join('","') + '"}'
      : undefined;
    const squadExp = selectedValues.AccountPayerSquad
      ? 'Squad={"' +
      selectedValues.AccountPayerSquad?.value +
      '"}'
      : undefined;
    const ecosystemExp = selectedValues.AccountPayerEcosystem.length > 0
      ? 'Ecosystem={"' + selectedValues.AccountPayerEcosystem.map((a) => a.label).join('","') + '"}'
      : undefined;
    const productExp = selectedValues.AccountPayerProduct?.value
      ? 'Product={"' + selectedValues.AccountPayerProduct?.value + '"}'
      : undefined;
    const timePeriodExp = selectedValues.AccountPayerTimePeriod.length > 0
      ? 'TimePeriod={"' + selectedValues.AccountPayerTimePeriod.value + '"}'
      : undefined;

    if (payerExp !== undefined && fieldName !== 'AccountPayerPayer') {
      payerFilterExpression = payerFilterExpression?.concat(`, ${payerExp}`);
    }
    if (ecosystemExp !== undefined && fieldName !== 'AccountPayerEcosystem') {
      payerFilterExpression = payerFilterExpression?.concat(
        `, ${ecosystemExp}`
      );
    }
    if (productExp !== undefined && fieldName !== 'AccountPayerProduct') {
      payerFilterExpression = payerFilterExpression?.concat(`, ${productExp}`);
    }
    if (squadExp !== undefined && fieldName !== 'AccountPayerSquad') {
      payerFilterExpression = payerFilterExpression?.concat(
        `, ${squadExp}`
      );
    }
    if (timePeriodExp !== undefined && fieldName !== 'AccountPayerTimePeriod') {
      payerFilterExpression = payerFilterExpression?.concat(
        `, ${timePeriodExp}`
      );
    }
    return payerFilterExpression;
  };

  const callLoadOptions = async (inputValue, firstLoad, fieldName) => {
    const params = {
      userContext: {
        unixId: user.unixId,
        roleCode: user.roleId
      },
      criteria: {
        accountId: '',
        fieldName,
        offset: inputValue !== '' ? searchedTexts[fieldName] === inputValue ? searchOffset[fieldName] : 0 : offset[fieldName],
        searchText: inputValue,
        expression: buildExpression(fieldName)
      }
    };
    const { result: { value: { value: { HasMoreElement, CurrentListSize, Values } } } } = await loadDropDown(params);
    // if (HasMoreElement) {
    if (inputValue === '') {
      setOffset((prevValue) => ({ ...prevValue, [fieldName]: offset[fieldName] + CurrentListSize }));
      setSearchOffset((prevValue) => ({ ...prevValue, [fieldName]: 0 }));
    }
    else if (searchedTexts[fieldName] === inputValue) {
      setSearchOffset((prevValue) => ({ ...prevValue, [fieldName]: prevValue[fieldName] + CurrentListSize }));
      setOffset((prevValue) => ({ ...prevValue, [fieldName]: 0 }));
    }
    else {
      setSearchOffset((prevValue) => ({ ...prevValue, [fieldName]: CurrentListSize }));
      setOffset((prevValue) => ({ ...prevValue, [fieldName]: 0 }));
    }
    // }
    setSearchedText({ ...searchedTexts, [fieldName]: inputValue })
    const response = {
      options: Values?.flatMap(item => ({ value: item, label: item })),
      hasMore: HasMoreElement,
    };
    return response
  };

  const handleOnChange = (value, field) => {
    let updatedValues = { ...selectedValues, [field]: value }
    setSelectedValues(updatedValues);
  }

  const updateCharts = async () => {
    const userContext = {
      unixId: user.unixId,
      roleCode: user.roleId
    }
    const marketShareByMonthParams = {
      userContext,
      filterCriteria: [
        {
          EcosystemName: selectedValues.AccountPayerEcosystem.map(x => x.value),
          Squad: selectedValues.AccountPayerSquad?.value || '',
          Product: selectedValues.AccountPayerProduct?.value || '',
          SortByMetric: "PayerMarketShare",
          SortByDirection: "ASC",
          MetricCriteriaType: "PayerSpecificMarketShare",
          Dimension: [
            "Month"
          ],
          CompetitorProducts: [
            "Kesimpta",
            "Tysabri"
          ],
          TimePeriodType: selectedValues.AccountPayerTimePeriod?.value,
          Payer: selectedValues.AccountPayerPayer.map(x => ({
            EntityName: x.value,
            EntityType: "Payer",
            MdmId: ""
          })),
          ParentPayer: [

          ],
          BookOfBusiness: [
            "Commercial",
            "Medicare"
          ],
          BenefitType: "Medical",
          Region: "MIDATLANTIC"
        }
      ]
    };

    const marketShareByEcosystemParams = {
      userContext,
      filterCriteria: [
        {
          EcosystemName: selectedValues.AccountPayerEcosystem.map(x => x.value),
          Squad: selectedValues.AccountPayerSquad?.value || '',
          Product: selectedValues.AccountPayerProduct?.value || '',
          SortByMetric: "PayerMarketShare",
          SortByDirection: "ASC",
          MetricCriteriaType: "PayerSpecificMarketShare",
          Dimension: [
            "Ecosystem"
          ],
          CompetitorProducts: [
            "Kesimpta",
            "Tysabri"
          ],
          TimePeriodType: selectedValues.AccountPayerTimePeriod?.value,
          Payer: selectedValues.AccountPayerPayer.map(x => ({
            EntityName: x.value,
            EntityType: "Payer",
            MdmId: ""
          })),
          ParentPayer: [

          ],
          BookOfBusiness: [
            "Commercial",
            "Medicare"
          ],
          BenefitType: "Medical",
          Region: "MIDATLANTIC"
        }
      ]
    }

    const marketShareByMonthAndPayerParams = {
      userContext,
      filterCriteria: [
        {
          EcosystemName: selectedValues.AccountPayerEcosystem.map(x => x.value),
          Squad: selectedValues.AccountPayerSquad?.value || '',
          Product: selectedValues.AccountPayerProduct?.value || '',
          SortByMetric: "PayerMarketShare",
          SortByDirection: "ASC",
          MetricCriteriaType: "PayerSpecificMarketShare",
          Dimension: [
            "Month",
            "Payer"
          ],
          CompetitorProducts: [
            "Kesimpta",
            "Tysabri"
          ],
          TimePeriodType: selectedValues.AccountPayerTimePeriod?.value,
          Payer: selectedValues.AccountPayerPayer.map(x => ({
            EntityName: x.value,
            EntityType: "Payer",
            MdmId: ""
          })),
          ParentPayer: [

          ],
          BookOfBusiness: [
            "Commercial",
            "Medicare"
          ],
          BenefitType: "Medical",
          Region: "MIDATLANTIC"
        }
      ]
    }

    loadChartData(marketShareByMonthParams).then(response => {
      const monthData = response?.result?.value?.value
      setMarketShareByMonthData(monthData[0].Label.length ? {
        ...marketShareByMonthData,
        xAxis: {
          ...marketShareByMonthData.xAxis,
          data: monthData[0]?.Label
        },
        yAxis: {
          ...marketShareByMonthData.yAxis,
          interval: 0.10,
          min: monthData[0]?.Values[0]?.Data?.sort()[0] - 0.5
        },
        series: [
          {
            data: monthData[0]?.Values[0]?.Data,
            type: 'line',
            smooth: false,
          },
        ]
      }
        : defaultMonthData
      );
    }).catch(e => {
      console.log(e)
    });

    loadChartData(marketShareByEcosystemParams).then(response => {
      const ecosystemData = response.result?.value?.value;
      setMarketShareByEcosystemData(ecosystemData[0].Label.length ? {
        ...marketShareByEcosystemData,
        xAxis: {
          ...marketShareByEcosystemData.xAxis,
          data: ecosystemData[0]?.Label
        },
        yAxis: {
          ...marketShareByEcosystemData.yAxis,
          interval: 0.10,
          min: ecosystemData[0].Values[0].Data.sort()[0] - 0.5
        },
        series: [
          {
            data: ecosystemData[0].Values[0].Data,
            type: 'bar',
            smooth: false,
          },
        ]
      }
        : defaultEcosystemData
      );
    }).catch(e => {
      console.log(e)
    });

    loadChartData(marketShareByMonthAndPayerParams).then(response => {
      const monthAndPayerData = response.result?.value?.value;
      if (monthAndPayerData[0].Label.length > 0) {
        const updatedValue = {
          ...marketShareByMonthAndPayerData,
          yAxis: {
            ...marketShareByMonthAndPayerData.yAxis,
            data: monthAndPayerData[0].Label,
          },
          series: monthAndPayerData[0].Values.map(x => ({
            name: x.Name,
            type: 'bar',
            stack: 'Total',
            emphasis: {
              focus: 'series'
            },
            data: x.Data
          }))
        }
        setMarketShareByMonthAndPayerData(updatedValue)
      } else {
        setMarketShareByMonthAndPayerData(defaultMonthPayerData)
      }
    }).catch(e => {
      console.log(e)
    });

  }
  const shouldLoadMoreOptions = (scrollHeight, clientHeight, scrollTop, fieldName) => {
    if (firstLoading.current[fieldName]) {
      firstLoading.current[fieldName] = false;
      return true;
    }
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    const loadMore = bottomBorder < scrollTop;
    shouldLoadMore.current.fieldName = loadMore;
    return loadMore;
  };


  return (
    <div className='full-width-in-container light-grey'>
      <div
        id='QuickAnalytics'
        className='pt-3'>
        <Row>
          <Col
            className='pt-3 d-flex align-item-center'
            md={6}>
            <p className='title title__bold title__medium mb-1'>Quick Analytics</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className='title__x-small'>Ecosystem</label>
            <GNEAsyncPagination
              value={selectedValues.AccountPayerEcosystem || ''}
              loadOptions={(inputValue, firstLoad) => callLoadOptions(inputValue, firstLoad, 'AccountPayerEcosystem')}
              onChange={(e) => handleOnChange(e, 'AccountPayerEcosystem')}
              isMulti={true}
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              placeholder={ALL}
              closeMenuOnSelect={false}
              isClearable
              shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => shouldLoadMoreOptions(scrollHeight, clientHeight, scrollTop, 'AccountPayerEcosystem')}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
            />
          </Col>
          <Col>
            <label className='title__x-small'>Product</label>
            <GNEAsyncPagination
              value={selectedValues.AccountPayerProduct || ''}
              loadOptions={(inputValue, firstLoad) => callLoadOptions(inputValue, firstLoad, 'AccountPayerProduct')}
              onChange={(e) => handleOnChange(e, 'AccountPayerProduct')}
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              placeholder={ALL}
              closeMenuOnSelect={false}
              isClearable
              shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => shouldLoadMoreOptions(scrollHeight, clientHeight, scrollTop, 'AccountPayerProduct')}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
            />
          </Col>
          <Col>
            <label className='title__x-small'>Squad</label>
            <GNEAsyncPagination
              value={selectedValues.AccountPayerSquad || ''}
              loadOptions={(inputValue, firstLoad) => callLoadOptions(inputValue, firstLoad, 'AccountPayerSquad')}
              onChange={(e) => handleOnChange(e, 'AccountPayerSquad')}
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              placeholder={ALL}
              closeMenuOnSelect={false}
              isClearable
              shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => shouldLoadMoreOptions(scrollHeight, clientHeight, scrollTop, 'AccountPayerSquad')}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
            />
          </Col>
          <Col>
            <label className='title__x-small'>Payer</label>
            <GNEAsyncPagination
              value={selectedValues.AccountPayerPayer || ''}
              loadOptions={(inputValue, firstLoad) => callLoadOptions(inputValue, firstLoad, 'AccountPayerPayer')}
              onChange={(e) => handleOnChange(e, 'AccountPayerPayer')}
              isMulti={true}
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              placeholder={ALL}
              closeMenuOnSelect={false}
              isClearable
              shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => shouldLoadMoreOptions(scrollHeight, clientHeight, scrollTop, 'AccountPayerPayer')}
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
            />
          </Col>
          <Col>
            <label className='title__x-small'>Time Period</label>
            <GNEAsyncPagination
              value={selectedValues.AccountPayerTimePeriod || ''}
              // loadOptions={(inputValue, firstLoad) => callLoadOptions(inputValue, firstLoad, 'AccountPayerTimePeriod')}
              options={[
                { value: 'R3M', label: 'R3M' },
                { value: 'R6M', label: 'R6M' },
                { value: 'R12M', label: 'R12M' }
              ]}
              onChange={(e) => handleOnChange(e, 'AccountPayerTimePeriod')}
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              closeMenuOnSelect
              getOptionValue={(option) => option?.value}
              getOptionLabel={(option) => option?.label}
              getOptionId={(option) => option?.id}
              formatOptionLabel={(data, { context }) =>
                formatOptionLabel(data, { context })
              }
            />
          </Col>
        </Row>
        &nbsp;
      </div>

      <Row
        id='QuickAnalytics'
        className={classes.sitePerformanceContainer}>
        <Col xs={4}>
          < ReactEcharts option={marketShareByMonthData} />
        </Col>
        <Col xs={4}>
          < ReactEcharts option={marketShareByEcosystemData} />
        </Col>
        <Col xs={4}>
          < ReactEcharts key={marketShareByMonthAndPayerData.series.length} option={marketShareByMonthAndPayerData} />
        </Col>
      </Row>
    </div>
  );
}

export default QuickAnalytics;