import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';

import AuthReducers from './auth/Authslice';
import HomeMyViewReducer from './home/homeMyViewSlice';
import CustomerViewReducer from './customer/customerViewSlice';
import PayerViewReducer from './payer/payerViewSlice';
import TimelineViewReducer from './customer/timeline/timeline-view-slice';
import FiltersReducer from './filters/filters-slice';
import EngagementFilterReducer from './customer/filter/filter-slice';
import EngagementReducer from './customer/engagement/engagement-slice';
import TrendsSliceReducer from './trends_v2/trendsSlice';
const rootPersistConfig = {
  key: 'C360',
  storage,
  whitelist: ['Auth', 'CustomerView', 'HomeMyView'],
};

const rootReducer = combineReducers({
  Auth: AuthReducers,
  HomeMyView: HomeMyViewReducer,
  CustomerView: CustomerViewReducer,
  PayerView: PayerViewReducer,
  Filters: FiltersReducer,
  TimelineView: TimelineViewReducer,
  EngagementFilters: EngagementFilterReducer,
  EngagementView: EngagementReducer,
  trends: TrendsSliceReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk],
});

export const persistor = persistStore(store);
export default store;
