import axiosDBClient from "axiosDBClient";

export const loadDropDown = async params => {
  try {
    const response = await axiosDBClient.post('palentir/dropdown', params);

    if (response.error) {
      throw new Error('Could not fetch dropdown data!');
    }
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

export const loadChartData = async params => {
  try {
    const response = await axiosDBClient.post('palentir/metrics', params);

    if (response.error) {
      throw new Error('Could not fetch metrics data!');
    }
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

export const loadTableData = async params => {
  try {
    const response = await axiosDBClient.post('palentir/table', params);

    if (response.error) {
      throw new Error('Could not fetch metrics data!');
    }
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}

export const exportTableData = async params => {
  try {
    const response = await axiosDBClient.post('palentir/export', params, {
      responseType: 'blob'
    });

    if (response.error) {
      throw new Error('Could not download table data!');
    }
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
}
