import { uniqBy } from 'lodash';
import { useRef, useState } from 'react';
import { useClickAway } from 'utils/hooks/useClickAway';

import {
  CustomDropdownIndicator,
  CustomMenuList,
  CustomMenuPortal,
  CustomValueContainer,
  handleInputChange,
  MenuListWithMoveItemTop,
} from 'utils/utils';

const ReactSelectSearch = (WrappedComponent) => {
  const WithSearch = (props) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const moveSelected = useRef({
      moveup: false,
      skipValues: [],
    });
    const containerRef = useRef();
    useClickAway(containerRef, () => {
      setInputValue('');
      setIsFocused(false);
    });

    const loadDropdownOptions = async (searchQuery = '', loadedOptions) => {
      const response = await props.loadOptions(searchQuery, loadedOptions);
      let options = uniqBy([...loadedOptions, ...response.options], 'value');
      if (props.isMulti && !searchQuery) {
        // Filter selected item while search
        let selectedWhileSearch = Array.isArray(props.value)
          ? props.value?.filter((item) => item.selectedWhileSearch)
          : [];

        // find the selected item while search to add in list to avoid duplicate (next page/search again scenario)
        let selectedWhileSearch1 = Array.isArray(props.value)
          ? props.value?.filter(
              (item) => !options.some((option) => option.value === item.value)
            )
          : [];

        if (selectedWhileSearch?.length > 0) {
          response.options = uniqBy(
            [...response.options, ...selectedWhileSearch1],
            'value'
          );
        }
      }
      return { ...response, options: response.options };
    };

    return (
      <div
        ref={containerRef}
        className={props.containerCss}>
        <WrappedComponent
          {...props}
          loadOptions={props.isMulti ? loadDropdownOptions : props.loadOptions}
          cacheUniqs={
            props.cacheUniqs && props.isMulti
              ? [...props.cacheUniqs, inputValue]
              : props.cacheUniqs
          }
          onMenuOpen={() => {
            moveSelected.current = { moveup: true, skipValues: [] };
            setInputValue('');
            props?.onMenuOpen && props?.onMenuOpen();
          }}
          onMenuClose={() => {
            setIsFocused(false);
            props?.onMenuClose && props?.onMenuClose();
          }}
          moveSelected={moveSelected.current}
          components={{
            DropdownIndicator: CustomDropdownIndicator,
            MenuPortal: CustomMenuPortal,
            MenuList: props.isMulti ? MenuListWithMoveItemTop : CustomMenuList,
            ValueContainer: CustomValueContainer,
            ...props?.components,
          }}
          inputValue={inputValue}
          isSearchable={false}
          onMenuInputFocus={() => setIsFocused(true)}
          menuPosition={props.menuPosition}
          onChange={(value) => {
            let updatedValue = value;
            if (props.isMulti && inputValue && value?.length > 0) {
              // Update current selected item with selectedWhileSearch flag, while search and select
              updatedValue[value?.length - 1] = {
                ...updatedValue[value?.length - 1],
                selectedWhileSearch: true,
              };
              props.onChange(updatedValue);
            } else {
              props.onChange(updatedValue);
            }

            if (!props.isMulti) {
              setInputValue('');
              setIsFocused(false);
            } else if (updatedValue?.length === 0 || !updatedValue) {
              // if we clear all values for multi select
              moveSelected.current = {
                moveup: false,
                skipValues: [],
              };
            } else {
              // find the record which is un selected
              const recod = props?.value?.find(
                (i) =>
                  i.value !==
                  updatedValue?.find((f) => f.value === i.value)?.value
              );

              // In case of un select the value
              if (recod && props?.value?.length > updatedValue?.length) {
                moveSelected.current = {
                  moveup: false,
                  skipValues: [...moveSelected.current?.skipValues, recod],
                };
              } else {
                // In case of select new value
                moveSelected.current = {
                  moveup: false,
                  skipValues: updatedValue?.at(-1)
                    ? [
                        ...moveSelected.current?.skipValues,
                        updatedValue?.at(-1),
                      ]
                    : moveSelected.current?.skipValues,
                };
              }
            }
          }}
          onInputChange={(value, action) => {
            props?.onDropdownAction && props?.onDropdownAction(action?.action);
            if (action.action === 'input-change') {
              setInputValue(value);
            }
            if (action.action === 'set-value') {
              return inputValue;
            }
            if (action.action === 'menu-close') {
              return action.prevInputValue;
            }
          }}
          // It is causing the default facus behaviour and creating issues while first time value selection in dropdown.
          // Need to find other solution for focusing the search box.
          onFocus={() => {
            if (!isFocused) {
              setTimeout(() => {
                document.querySelector('.search-box')?.focus();
              });
            }
          }}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined,
          }}
        />
      </div>
    );
  };

  return WithSearch;
};
export default ReactSelectSearch;
